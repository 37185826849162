import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              <Tab>All</Tab>
              <Tab>First Year</Tab>
              <Tab>Second Year</Tab>
              <Tab>Third Year</Tab>
              <Tab>Fourth Year</Tab>
            </TabList>
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Student Placements</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <img src="img/portfolio/1.jpg" alt="Motion Graphy" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="100"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Every Voter</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/3.jpg" className="gallery-link">
                          <img src="img/portfolio/3.jpg" alt="Elearning App" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Voter Satisfation</h5>
                          <span>Market Study</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <img src="img/portfolio/2.jpg" alt="Visual Design" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Supporters</h5>
                          <span>Bangalore</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <img src="img/portfolio/4.jpg" alt="Business Card" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="100"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Vote Projection</h5>
                          <span>Karnataka</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/5.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/5.jpg"
                            alt="Chatting Application"
                          />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Budget Balance</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/6.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/6.jpg"
                            alt="Web Application"
                          />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>
              {/* END ALL PORTFOLIO GALLERY */}

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Student Placements</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <img src="img/portfolio/1.jpg" alt="Motion Graphy" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="100"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Voter Satisfation</h5>
                          <span>Market Study</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <img src="img/portfolio/2.jpg" alt="Visual Design" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Vote Projection</h5>
                          <span>Karnataka</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/5.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/5.jpg"
                            alt="Chatting Application"
                          />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Supporters</h5>
                          <span>Bangalore</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <img src="img/portfolio/4.jpg" alt="Business Card" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                    data-aos-delay="100"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Budget Balance</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/6.jpg" className="gallery-link">
                          <img
                            src="img/portfolio/6.jpg"
                            alt="Web Application"
                          />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div
                    className="col-sm-6 col-lg-4 grid-item product"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Student Placements</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <img src="img/portfolio/1.jpg" alt="Motion Graphy" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div
                      className="portfolio-box-01"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Supporters</h5>
                          <span>Bangalore</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/4.jpg" className="gallery-link">
                          <img src="img/portfolio/4.jpg" alt="Business Card" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div
                      className="portfolio-box-01"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Student Placements</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/1.jpg" className="gallery-link">
                          <img src="img/portfolio/1.jpg" alt="Motion Graphy" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div
                      className="portfolio-box-01"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>Every Voter</h5>
                          <span>Hassan</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/2.jpg" className="gallery-link">
                          <img src="img/portfolio/2.jpg" alt="Elearning App" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}

                  <div className="col-sm-6 col-lg-4 grid-item product">
                    <div
                      className="portfolio-box-01"
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="200"
                    >
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                        <h5>Voter Satisfation</h5>
                          <span>Market Study</span>
                        </div>
                        {/* End .portfolio-info */}
                        <a href="img/portfolio/3.jpg" className="gallery-link">
                          <img src="img/portfolio/3.jpg" alt="Visual Design" />

                          <div className="portfolio-icon">
                            <span className="ti-plus"></span>
                          </div>
                          {/* End .portfolio-icon */}
                        </a>
                        {/* End gallery link */}
                      </div>
                    </div>
                  </div>
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>
            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
