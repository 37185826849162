import React from "react";

const About = () => {
  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="img-box">
            <img src="img/about/about-4.jpg" alt="smiling a girl" />
          </div>
        </div>

        <div
          className="col-lg-6 my-3"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="typo-box about-me">
            <h3>Preetham J Gowda</h3>
            <h5>
              Preetham is a <span className="color-theme">MLA </span> 
              based in <span className="color-theme">Hassan</span>
            </h5>
            <p>
            Preetham J Gowda, MLA, Hassan Constituency has studied engineering from Malnad College and is also a reputed politician of Karnataka. He is a real hero who shows path to the youth. He is always engaged in the upliftment of the society. He loves to serve the nation.  He is a rare person who goes out of his way to help the less fortunate. He focuses on equality of opportunity, independent living, economic self-sufficiency, and full participation.

            </p><p>

            He always wanted to show a path to the youth. A path which is full of spirituality & divineness. A path which shows how to lead a happy & stress free life.  Through this he wants to get connected to the youth of the entire Karnataka.
            </p>
            <div className="row about-list">
              <div className="col-md-6">
                <div className="media">
                  <label>Birthday</label>
                  <p>14 November 1981</p>
                </div>
                <div className="media">
                  <label>Age</label>
                  <p>39 Yr</p>
                </div>
                <div className="media">
                  <label>Residence</label>
                  <p>Hassan</p>
                </div>
                <div className="media">
                  <label>Address</label>
                  <p>Karnataka, India</p>
                </div>
              </div>
              {/* End .col */}

              <div className="col-md-6">
                <div className="media">
                  <label>E-mail</label>
                  <p>help@preethamjgowda.com</p>
                </div>
                <div className="media">
                  <label>Whatsapp</label>
                  <p>+91-9898989898</p>
                </div>
                <div className="media">
                  <label>Skype</label>
                  <p>preetham.j.gowda</p>
                </div>
                <div className="media">
                  <label>Instagram</label>
                  <p>nimma_preetham</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
            {/* <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="img/resume.png" download>
                Download CV
              </a>
            </div> */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
