import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="sm-title">
            <h4 className="font-alt">My Education</h4>
          </div>

          <ul className="aducation-box theme-bg">
            <li>
              <span className="dark-bg">2000-2004</span>
              <h6>Computer Science Engineer</h6>
              <p>Malanad Collecge Of Engineering</p>
            </li>

            <li>
              <span className="dark-bg">1998-2000</span>
              <h6>PUC</h6>
              <p>Hassan PU College</p>
            </li>

            <li>
              <span className="dark-bg">1985-1997</span>
              <h6>SSLC</h6>
              <p>Hassan Higher Secondary School</p>
            </li>
          </ul>

          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">My Career</h4>
          </div>
          <ul className="aducation-box dark-bg">
            <li>
              <span className="theme-bg">2018-2023</span>
              <h6>Member of Legislative Assembly</h6>
              <p>Hassan Constituency</p>
            </li>

            <li>
              <span className="theme-bg">2012-2018</span>
              <h6>Commitee Leader</h6>
              <p>BJP Hassan Karnataka</p>
            </li>

            <li>
              <span className="theme-bg">2008-2012</span>
              <h6>Commitee Member</h6>
              <p>BJP Hassan Karnataka</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 ml-auto my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="sm-title">
            <h4 className="font-alt">My Targets</h4>
          </div>
          <div className="gray-bg skill-box">
            <div className="skill-lt">
              <h6>1000 QA Automation Engineers</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 82 + "%" }}
                >
                  <span>82%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>1000 Java Developers</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 55 + "%" }}
                >
                  <span>55%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>100 MBBS Doctors</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 86 + "%" }}
                >
                  <span>86%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>100 Entrepreneurs</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 52 + "%" }}
                >
                  <span>52%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>100 Teachers</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 92 + "%" }}
                >
                  <span>92%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
          </div>
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
